import React from "react"
import { Image, Text, Link } from "rebass"
import styled from "styled-components"

import Layout from "../components/layout"
import Meowcintosh from "../images/meowcintosh.png"

const Heading = styled(Text)`
  font-family: adrianna, sans-serif;
  font-size: 2.4rem;

  @media (max-width: 429px) {
    font-size: 2rem;
  }
`

const Github = styled(Link)`
  font-family: adrianna, sans-serif;
`

const IndexPage = () => (
  <Layout>
    <Image
      src={Meowcintosh}
      alt="meowcintosh"
      mb={4}
      style={{ maxHeight: "500px" }}
    />
    <Heading textAlign="center">under construction</Heading>
    <Github mt={3} href="https://github.com/ryee-dev" target="_blank">
      Want to check out my Github?
    </Github>
  </Layout>
)

export default IndexPage
